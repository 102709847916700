// extracted by mini-css-extract-plugin
export var closeButton = "Header-module--closeButton--8GiPY";
export var container = "Header-module--container--NrfoN";
export var desktop = "Header-module--desktop--NHFb3";
export var element = "Header-module--element--gmtIL";
export var loginButton = "Header-module--loginButton--g4mo9";
export var menu = "Header-module--menu--2fKU9";
export var menuItem = "Header-module--menuItem--+sPU-";
export var nav = "Header-module--nav--YUWrM";
export var openButton = "Header-module--open-button--f2y9C";
export var responsive = "Header-module--responsive--P8PEz";
export var responsiveMenu = "Header-module--responsive-menu--2Q7+u";
export var wrapper = "Header-module--wrapper--ayY-E";