import "styles/index.scss";

import React, { FC } from "react";

import CookieConsent from "components/molecules/CookieConsent";
import Footer from "components/organisms/Footer";
import Header from "components/organisms/Header";
import Seo from "seo";

import IDefaultLayout from "./DefaultLayout.type";

const DefaultLayout: FC<IDefaultLayout> = ({ children }) => {
  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MZP9Z42"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>

      <Seo />
      <Header />
      <div id="modal-root" />
      <main>{children}</main>
      <CookieConsent />
      <Footer />
    </>
  );
};

export default DefaultLayout;
