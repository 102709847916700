import React, { FC } from "react";

import * as styles from "./FooterContact.module.scss";
import IFooterContact from "./FooterContact.type";

const FooterContact: FC<IFooterContact> = ({ items }) => {
  return (
    <div className={styles.element} id="kontakt">
      {items.map(({ label, icon, address, mailto, text }) => (
        <div key={label} className={styles.contactItem}>
          <div className={styles.iconWrapper}>
            <img src={icon} alt="" />
          </div>
          <span className="h4">{label}</span>
          {address && <address className="s-medium">{address}</address>}
          {text && <p className="s-medium">{text}</p>}
          {mailto && (
            <a className="s-medium" href={`mailto:${mailto}`}>
              kontakt@lendidea.com
            </a>
          )}
        </div>
      ))}
    </div>
  );
};

export default FooterContact;
