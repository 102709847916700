import React, { FC } from "react";

import lendideaLogo from "assets/vector/lendidea-logo.svg";
import ueLogo from "assets/raster/ue-small.png";
import { Link } from "gatsby";

import * as styles from "./SiteLogo.module.scss";
import cn from "utils/cn";

interface ISiteLogo extends React.ComponentProps<"a"> {
  withUeLogo?: boolean;
}

const SiteLogo: FC<ISiteLogo> = ({ className, withUeLogo }) => (
  <Link className={cn(className, styles.element)} to="/">
    <img src={lendideaLogo} alt="logo Lendidea" />
    {withUeLogo && <img src={ueLogo} alt="logo Funduszy Europejskich" />}
  </Link>
);

export default SiteLogo;
