import React from "react";

import favicon from "assets/favicon.ico";
import { Helmet } from "react-helmet";

const Seo: React.FC = () => {
  return (
    <Helmet
      title="Lendidea - Zacznij zarabiać inwestując w nieruchomości"
      link={[
        {
          href: favicon,
          rel: "icon",
          type: "image/x-icon",
        },
      ]}
      script={[
        {
          type: "text/javascript",
          innerHTML: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MZP9Z42');
        `,
        },
      ]}
    ></Helmet>
  );
};

export default Seo;
