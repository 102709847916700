import React, { useCallback, /* useEffect, */ useState } from "react";

import closeIcon from "assets/vector/close-icon.svg";
import hamburgerIcon from "assets/vector/hamburger-menu.svg";
import SiteLogo from "components/molecules/SiteLogo";
import { Link } from "gatsby";
import cn from "utils/cn";

import * as styles from "./Header.module.scss";
// import ModalWindow from "../ModalWindow";
// import LoginForm from "../LoginForm";
// import PickAccountType from "../PickAccountType";
// import ForgotPassword from "../ForgotPassword";

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  // const [showModal, setShowModal] = useState<"login" | "register" | "forgot-password" | null>(null);
  const onOpenMenuClick = useCallback(() => setMenuOpen(true), []);
  const onCloseMenuClick = useCallback(() => setMenuOpen(false), []);

  // const onOpenLoginModalClick = useCallback(() => setShowModal("login"), []);
  // const onRegisterClick = useCallback(() => setShowModal("register"), []);
  // const onForgotPassowordClick = useCallback(() => setShowModal("forgot-password"), []);
  // const onCloseModal = useCallback(() => setShowModal(null), []);

  // useEffect(() => {
  //   if (["#login", "#register", "#forgot-password"].includes(window.location.hash)) {
  //     setShowModal(window.location.hash.slice(1) as "login" | "register" | "forgot-password");
  //   }
  // }, []);

  // useEffect(() => {
  //   if (showModal) window.location.hash = `#${showModal}`;
  //   else history.replaceState(null, "", " ");
  // }, [showModal]);

  return (
    <header className={styles.element}>
      <div className={styles.wrapper}>
        <div className={cn(styles.container, "container")}>
          <nav className={styles.nav}>
            <SiteLogo withUeLogo />
            <button className={styles.openButton} onClick={onOpenMenuClick}>
              <img src={hamburgerIcon} alt="otwórz menu" />
            </button>
            <ul className={cn(styles.menu)}>
              {menuItems.map((item) => (
                <li key={item.label}>
                  <Link to={item.href} className={cn(styles.menuItem, "s-large")}>
                    {item.label}
                  </Link>
                </li>
              ))}
              {/* <li>
                <button className={cn(styles.menuItem, styles.loginButton, "s-large")} onClick={onOpenLoginModalClick}>
                  Zaloguj się
                </button>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
      <div className={styles.responsive} style={{ transform: menuOpen ? "translate(0)" : "" }}>
        <div className={cn(styles.container, "container")}>
          <nav className={styles.responsiveNav}>
            <SiteLogo withUeLogo />
            <button className={styles.closeButton} onClick={onCloseMenuClick}>
              <img src={closeIcon} alt="zamknij menu" />
            </button>
            <ul className={styles.responsiveMenu}>
              {menuItems.map((item) => (
                <Link key={item.label} to={item.href} className={cn(styles.menuItem, "h4 c-navy")}>
                  {item.label}
                </Link>
              ))}
              {/* <li>
                <button className={cn(styles.menuItem, styles.loginButton, "s-large")} onClick={onOpenLoginModalClick}>
                  Zaloguj się
                </button>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
      {/* <ModalWindow isOpen={!!showModal} onClose={onCloseModal}>
        {showModal === "login" && (
          <LoginForm onRegisterClick={onRegisterClick} onForgotPassowordClick={onForgotPassowordClick} />
        )}
        {showModal === "register" && ( //
          <PickAccountType />
        )}
        {showModal === "forgot-password" && ( //
          <ForgotPassword />
        )}
      </ModalWindow> */}
    </header>
  );
};

export default Header;

const menuItems = [
  {
    label: "Jak to działa?",
    href: "/#jak-to-dziala",
  },
  // {
  //   label: "Poradniki",
  //   href: "/poradniki",
  // },
  {
    label: "O nas",
    href: "/o-nas",
  },
  {
    label: "Kontakt",
    href: "/#kontakt",
  },
];
