import React, { FC } from "react";

import alarmIcon from "assets/vector/arcticons_alarmio.svg";
import mailIcon from "assets/vector/arcticons_libremmail.svg";
import mapsIcon from "assets/vector/arcticons_maps.svg";
import SiteLogo from "components/molecules/SiteLogo";
import cn from "utils/cn";

import * as styles from "./Footer.module.scss";
import IFooter from "./Footer.type";
import FooterContact from "./FooterContact";
import { TFooterContactItem } from "./FooterContact/FooterContact.type";
import FooterMenu from "./FooterMenu";
import { TFooterMenuItem } from "./FooterMenu/FooterMenu.type";
import { StaticImage } from "gatsby-plugin-image";

const Footer: FC<IFooter> = () => {
  return (
    <footer className={styles.element}>
      <div className={cn(styles.container, "container")}>
        <div className={styles.top}>
          <FooterContact items={contactItems} />
          <FooterMenu items={menuItems} />
        </div>
        <div className={styles.patrons}>
          <StaticImage
            className={styles.image}
            src="../../../assets/raster/ue-funds.jpg"
            alt="Logo Unii Europejskiej, Flaga polski i logo Funduszy Europejskich"
            placeholder="none"
          />
        </div>
        <div className={styles.bottom}>
          <SiteLogo />
          <div className={styles.right}>
            <p className={cn(styles.description, "s-medium")}>
              Lendidea.com to nowoczesny marketplace dla osób poszukujących kapitału oraz inwestorów zainteresowanych
              bezpiecznymi formami lokowania kapitału. Każda inwestycja zabezpieczona jest na majątku zgłaszającego
              projekt, stopień zabezpieczenia jest zależny od zgłoszonych i zweryfikowanych parametrów oferty, stosujemy
              szeroki katalog form zabezpieczenia, tak aby inwestor decydujący się na dany projekt, miał pewność
              bezpieczeństwa środków. Stawiamy sobię za cel dokładną weryfikację uczestników transakcji aby cały jej
              proces przebiegł sprawnie i z korzyścią dla każdego z uczestników.
            </p>
            <p className="s-medium c-gray">© 2022 All rights reserved | Lendidea.com</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const menuItems: TFooterMenuItem[] = [
  // { label: "Poradniki", href: "#" },
  { label: "O nas", href: "/o-nas" },
  { label: "Jak to działa?", href: "#jak-to-dziala" },
  { label: "Polityka cookies", href: "/polityka-prywatnosci" },
];

const contactItems: TFooterContactItem[] = [
  {
    label: "Adres",
    icon: mapsIcon,
    address: "ul. Żurawia 71,\n15-540 Białystok",
  },
  {
    label: "Godziny\notwarcia",
    icon: alarmIcon,
    text: "Jesteśmy platformą online,\ndziałamy całą dobę",
  },
  {
    label: "Napisz\ndo nas",
    icon: mailIcon,
    mailto: "kontakt@lendidea.com",
  },
];
