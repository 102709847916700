import React from "react";

import { Link } from "gatsby";
import ReactCookieConsent from "react-cookie-consent";

import * as styles from "./CookieConsent.module.scss";

const CookieConsent = () => {
  return (
    <ReactCookieConsent
      contentClasses={styles.text}
      containerClasses={styles.banner}
      buttonText="Zaakceptuj"
      buttonStyle={{
        backgroundColor: "var(--blue)",
        fontSize: "14px",
        fontWeight: "600",
        color: "white",
        padding: "16px 30px",
        borderRadius: "8px",
      }}
      ariaAcceptLabel="Zaakceptuj"
      cookieName="LENDIDEA_COOKIE_CONSENT"
    >
      Używamy plików cookies na naszej stronie. Przeczytaj naszą{" "}
      <Link to="#polityka-prywatnosci">Politykę Prywatności</Link>
    </ReactCookieConsent>
  );
};

export default CookieConsent;
