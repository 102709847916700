import { Link } from "gatsby";
import React, { FC } from "react";

import * as styles from "./FooterMenu.module.scss";
import IFooterMenu from "./FooterMenu.type";

const FooterMenu: FC<IFooterMenu> = ({ items }) => {
  return (
    <ul className={styles.menu}>
      {items.map((item) => (
        <li key={item.label} className={styles.menuItem}>
          <Link to={item.href}>{item.label}</Link>
        </li>
      ))}
    </ul>
  );
};

export default FooterMenu;
